<template>
    <div>
        <div v-if="loading">
            <span>Aguarde, estamos carregando os dados, isso pode levar alguns segundos...</span>
            <Loading type="line" :center="false" />
        </div>
        <div v-else>
            <b-row v-if="numberOfOpenFinance > 0">
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <RadioGroup :options="optionsConditional" v-model="type" />
                </b-col>
            </b-row>

            <div v-if="type == 1">
                <Alert type="info">
                    <span>
                        Selecione o arquivo no formato
                        <b> .ofx </b> e aguarde até carregar os dados para conciliação.
                    </span>
                    <br />
                </Alert>
                <FileUpload title="Importar Arquivo .OFX" classIcon="fa-solid fa-file-export"
                    :container="bankReconciliation.container" accepted=".ofx" allowed=".ofx" :disabled="true"
                    urlPost="/api/v1/adm/file-upload/upload" urlRemove="/api/v1/adm/file-upload/delete"
                    :onLoad="successUploadFile" :nameDataBase="bankReconciliation.fileName"
                    v-model="bankReconciliation.fileName" />
            </div>
            <div v-else>
                <div v-if="loadingTransactionsToday">
                    <span>Aguarde, estamos atualizado o extrato na data de HOJE...</span>
                    <Loading type="line" :center="false" />
                </div>
                <div v-else>
                    <Alert type="info">
                        <span>
                            Importe os extratos das <b>Contas Vinculadas</b> ao Open Finance. Se <b>NÃO</b> houver
                            nenhuma
                            listada, adicione-as no cadastro da Conta Bancária.
                        </span>
                        <br />
                    </Alert>
                    <Button _key="btnOpenModalImport" title="Importar Período" backGroundColor="#0A36DB" color="white"
                        classIcon="fa-solid fa-file-import" size="small" :clicked="openModalImport" />
                </div>
            </div>
        </div>
        <br>
        <b-row>
            <b-col sm="6">
                <TransactionsList v-if="!loading" />
            </b-col>
            <b-col sm="6">
                <SuggestionList v-if="!loading" />
            </b-col>
        </b-row>
        <!--não remover o v-show, pois está sendo acionado ao carregar o componente-->
        <Modal title="Importar Extrato" :width="550" v-show="showModal('importPluggy')">
            <ImportPluggy v-model="loadingTransactionsToday" />
        </Modal>
    </div>
</template>

<script>


import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import BankReconciliation from "@/components/modules/finance/bank-reconciliation/BankReconciliation.js";
import FileUpload from "@nixweb/nixloc-ui/src/component/forms/FileUpload";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import TransactionsList from './TransactionsList.vue'
import SuggestionList from './SuggestionList.vue'
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import ImportPluggy from './ImportPluggy.vue'

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "BankReconciliation",
    components: {
        FileUpload,
        Alert,
        Loading,
        Molded,
        TransactionsList,
        SuggestionList,
        RadioGroup,
        Button,
        Modal,
        ImportPluggy
    },
    data() {
        return {
            bankReconciliation: new BankReconciliation(),
            urlCreate: "/api/v1/finance/bank-reconciliation/create",
            urlGetPlan: "/api/v1/adm/company/get-plan",
            loading: false,
            type: 1,
            bankAccount: {},
            numberOfOpenFinance: 0,
            loadingTransactionsToday: false,
        }
    },
    created() {
        this.getPlan();
    },
    computed: {
        ...mapGetters("generic", ["showModal"]),
        optionsConditional() {
            let options = [];
            var ofx = { text: 'Arquivo .OFX', value: 1 };
            var openFinance = { text: 'Conexão Automática', value: 2 };

            options.push(ofx);
            if (this.numberOfOpenFinance > 0) {
                this.type = 2;
                options.push(openFinance);
            }

            return options;
        }
    },
    methods: {
        ...mapActions("generic", ["postApi", "getApi"]),
        ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
        // o getPlan está provisório, após teste liberar para qualquer cliente e validar pela quantidade de contas bancárias contratadas
        getPlan() {
            let params = {
                url: this.urlGetPlan,
            };
            this.getApi(params).then((response) => {
                this.numberOfOpenFinance = response.content.plan.numberOfOpenFinance;
            });
        },
        successUploadFile() {
            this.loading = true;
            let self = this;
            setTimeout(function () {
                self.create();
            }, 100);
        },
        create() {
            let params = { url: this.urlCreate, obj: this.bankReconciliation, notNotifyToast: true, };
            this.postApi(params).then((response) => {
                this.loading = false;
                this.bankReconciliation.fileName = "";
                this.addEvent({ name: "fileLoaded" });
            });
        },
        openModalImport() {
            this.openModal("importPluggy");
            this.removeLoading(["btnOpenModalImport"]);
        }
    },
}
</script>
<style>
.div-file {
    margin-top: 10px;
}
</style>