<template>
  <div>
    <div class="fixed-bar-options" posicao="topo" :showButtonClose="true" v-show="totalSelected > 0">
      <div>
        <Button v-if="showBtnAdd" _key="btnAddReconcileItem" type="primary" :title="'Adicionar ' + totalSelected"
          size="small" eventName="addReconcileItemFixedBar" />
        <Button v-if="showBtnReconcile" _key="btnReconcileItem" type="primary" :title="'Conciliar ' + totalSelected"
          size="small" eventName="reconcileItemFixedBar" />
        <Button v-if="showBtnDelete" _key="btnRemoveItem" type="danger" :title="'Remover ' + totalSelected" size="small"
          eventName="removeItemFixedBar" />
      </div>
    </div>
  </div>
</template>
<script>
import FixedBar from "@nixweb/nixloc-ui/src/component/layout/FixedBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";

export default {
  name: "ItemFixedBar",
  components: { FixedBar, Button, },
  props: {
    selected: Array,
    showBtnAdd: Boolean,
    showBtnDelete: Boolean,
    showBtnReconcile: Boolean,
  },
  computed: {
    totalSelected() {
      return this.selected.length;
    },
  },
};
</script>
<style scoped>
.total-selected {
  font-size: 16px;
}
</style>