<template>
    <div>
        <Panel module="Financeiro" title="Conciliação Bancária" :showFilter="false" :showSearch="false"
            :showButtons="false">
            <div slot="content-main">
                <div class="main-reconciliation">
                    <BankReconciliation />
                </div>
            </div>
        </Panel>
    </div>
</template>
  
<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import BankReconciliation from '../../../components/modules/finance/bank-reconciliation/BankReconciliation.vue'

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";


export default {
    name: "BankReconciliationView",
    components: {
        Panel, BankReconciliation
    },
    data() {
        return {

        };
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),
    },
    watch: {

    },
};
</script>
  
<style scoped>
.main-reconciliation {
    margin-top: 40px;
}
</style>