<template>
    <div>
        <div>
            <b-row>
                <b-col sm="4">
                    <Select title="Categoria (Padrão) " url="api/v1/finance/chart-of-accounts/select-all"
                        :showNewRegister="false" v-model="chartOfAccounts">
                    </Select>
                </b-col>
                <b-col sm="4">
                    <Select title="Tipo de Pagamento (Padrão)" url="/api/v1/finance/type-payment/select-all"
                        :showNewRegister="false" v-model="typePayment">
                    </Select>
                </b-col>
                <b-col sm="4">
                    <Select title="Conta Bancária (Padrão)" url="api/v1/finance/bank-account/select-all"
                        :showNewRegister="false" v-model="bankAccount">
                    </Select>
                </b-col>
            </b-row>
            <br>
        </div>
        <div class="div-alert" v-if="!isValid && selected.length > 0">
            <Alert type="danger">
                <span>
                    O total para conciliação <b>{{ totalSelected | currency }}</b>
                    está diferente do total da transação <b>{{ value | currency }}</b>!
                </span>
            </Alert>
        </div>
        <div class="main-reconciliation">
            <div v-for="item in selected">
                <div class="div-molded">
                    <Molded>
                        <ItemReconciliation :item="item" :removed="removeItem" />
                    </Molded>
                </div>
            </div>
            <div v-show="selected.length == 0">
                <span>Nenhum registro selecionado!</span>
            </div>
        </div>
        <div class="div-btn text-center">
            <Button _key="btnReconciliation" :disabled="!isValid" type="success" title="Gerar Conciliação" size="medium"
                :clicked="reconciliation" />
        </div>
    </div>
</template>

<script>

import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";

import ItemReconciliation from './ItemReconciliation.vue'

import { mapActions, mapMutations } from "vuex";

export default {
    name: "ReconciliationList",
    props: {
        selected: Array,
        id: String,
        value: Number
    },
    components: {
        ScrollBar, Select, Molded, InputDecimal, Alert, Button, ItemReconciliation
    },
    data() {
        return {
            urlReconciliation: "/api/v1/finance/bank-reconciliation/reconciliation",
            chartOfAccounts: {},
            typePayment: {},
            bankAccount: {},
        }
    },
    computed: {
        totalSelected() {
            const sum = this.selected.reduce((accumulator, obj) => {
                return accumulator + (obj.value);
            }, 0);
            return sum;
        },
        isValid() {
            if (this.totalSelected.toFixed(2) != this.value.toFixed(2)) return false;
            return true;
        }
    },
    methods: {
        ...mapActions("generic", ["postApi"]),
        ...mapMutations("generic", ["addEvent", "hideModal", "removeLoading"]),
        reconciliation() {
            let params = {
                url: this.urlReconciliation,
                obj:
                    { id: this.id, listBankReconciliationResponse: this.selected },
                notNotifyToast: true,
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.hideModal();
                    this.addEvent({ name: "reconciliationExecuted" });
                }

                this.removeLoading(["btnReconciliation"]);
            });
        },
        removeItem(id) {
            let filter = this.selected.filter(function (item) {
                return item.id != id;
            });
            this.selected = filter;
        },
    },
    watch: {
        chartOfAccounts: {
            handler(chartOfAccounts) {
                this.selected.forEach(item => {
                    item.chartOfAccounts = { id: chartOfAccounts.id, content: chartOfAccounts.content }
                });
            },
            deep: true,
        },
        typePayment: {
            handler(typePayment) {
                this.selected.forEach(item => {
                    item.typePayment = { id: typePayment.id, content: typePayment.content }
                });
            },
            deep: true,
        },
        bankAccount: {
            handler(bankAccount) {
                this.selected.forEach(item => {
                    item.bankAccount = { id: bankAccount.id, content: bankAccount.content }
                });
            },
            deep: true,
        },
    },


}
</script>
<style scoped>
.main-reconciliation {
    height: 500px;
    overflow: auto;
    padding-right: 10px;
}

.div-molded {
    margin-bottom: 10px;
}

.pay {
    color: red;
}

.receive {
    color: darkblue;
}

.icon-delete {
    font-size: 16px;
    color: red;
    cursor: pointer;
}

.div-alert {
    margin-bottom: 20px;
}

.div-btn {
    margin: 10px
}

.div-description {
    margin-bottom: 10px;
}
</style>