<template>
    <div>
        <div class="div-title">
            <span class="title">
                <i class="fa-solid fa-money-bill-transfer"></i>
                Transações pendentes
            </span>
        </div>
        <Molded>
            <Loading type="line" :center="false" v-show="loading" />
            <ItemFixedBar :selected="selected" :showBtnDelete="true" :showBtnAdd="true" />
            <ScrollBar :minHeight="300" :maxHeight="600">
                <table class="table table-responsive-xs">
                    <thead>
                        <tr>
                            <th>
                                <b-form-checkbox v-model="showSelectAll" @change="selectAll"
                                    v-show="transactions.length > 0">
                                </b-form-checkbox>
                            </th>
                            <th>
                                <span class="title-header">Descrição</span>
                            </th>
                            <th class="text-center">
                                <span class="title-header">Data/Valor</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody v-for="item in transactions">
                        <tr :class="{ pay: item.type == 1, receive: item.type == 2, check: idSelected == item.id }">
                            <td>
                                <b-form-checkbox v-model="selected" :value="item.id" @change="selectComplete(item)" />
                            </td>
                            <td class="td-description" @click="select(item)">
                                {{ item.description }}
                            </td>
                            <td class="text-center" @click="select(item)">
                                <span> {{ item.dueDate }}</span><br>
                                {{ item.value | currency }}
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-show="transactions.length == 0">
                        <tr>
                            <td colspan="12">
                                <span>Nenhuma transação encontrada!</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ScrollBar>
        </Molded>
        <Modal title="Conciliação" :width="1200" :height="750" v-if="showModal('addTransactions')"
            :onHideModal="onHideModal">
            <AddTransactionsList :selected="selectedAll" />
        </Modal>
    </div>
</template>
<script>


import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import AddTransactionsList from './AddTransactionsList.vue'
import ItemFixedBar from './ItemFixedBar.vue'

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
    name: "TransactionsList",
    components: { Molded, ScrollBar, Loading, ItemFixedBar, Modal, AddTransactionsList },
    data() {
        return {
            loading: true,
            urlGetApi: "/api/v1/finance/bank-reconciliation/get-all",
            urlDelete: "/api/v1/finance/bank-reconciliation/delete",
            transactions: [],
            selected: [],
            selectedAll: [],
            showSelectAll: false,
            idSelected: ""
        };
    },
    mounted() {
        this.getAll();
    },
    computed: {
        ...mapState("generic", ["event"]),
        ...mapGetters("generic", ["showModal"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading", "openModal"]),
        ...mapActions("generic", ["getApi", "postApi", "deleteAllApi"]),
        getAll() {
            this.loading = true;
            let params = { url: this.urlGetApi, obj: { noPaginated: true } };
            this.getApi(params).then((response) => {
                this.transactions = response.content;
                this.loading = false;
            });
        },
        // nesse caso precisa remover com post, pois na rota é limitado em 50 ids
        removeSelected() {
            let params = { url: this.urlDelete, obj: { ids: this.selected } };
            console.log(params);
            this.postApi(params).then((reponse) => {
                if (reponse.success) {
                    this.removeLoading(["btnRemoveItem"]);
                    this.selected = [];
                    this.getAll();
                }
            });
        },
        select(item) {
            this.idSelected = item.id;
            this.addEvent({ name: "transactionSelected", data: item });
        },
        selectAll() {
            this.selected = [];
            if (this.showSelectAll) {
                for (let i in this.transactions) {
                    this.selected.push(this.transactions[i].id);
                    this.selectedAll.push(this.transactions[i]);
                }
            }
        },
        selectComplete(item) {
            this.selectedAll.push(item);
        },
        onHideModal() {
            this.selected = [];
            this.selectedAll = [];
            this.showSelectAll = false;
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "removeItemFixedBar") {
                    this.removeSelected();
                }

                if (event.name == "addReconcileItemFixedBar") {
                    this.openModal("addTransactions");
                    this.removeLoading(["btnAddReconcileItem"]);
                }

                if (event.name == "reconciliationAddExecuted" || event.name == "reconciliationExecuted" || event.name == "importPluggy") {
                    this.selected = [];
                    this.selectedAll = [];
                    this.showSelectAll = false;
                    this.getAll();
                }
            },
            deep: true,
        },
    },
};
</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.table tr {
    cursor: pointer;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.td-description {
    width: 400px;
}

.pay {
    color: red;
}

.receive {
    color: darkblue;
}

.check {
    background-color: #FFFBE6;

}
</style>