<template>
    <div>
        <Select title="Conta Bancária" field="bankAccount" :required="true" v-model="obj.bankAccount"
            url="api/v1/finance/bank-account/select-all-open-finance" :showNewRegister="false" />
        <b-row>
            <b-col sm="6">
                <DateTime title="Data Inicio" field="dueDate" format="DD/MM/YYYY" type="date" placeholder
                    :required="true" v-model="obj.dateFrom" />
            </b-col>
            <b-col sm="6">
                <DateTime title="Data Fim" field="dueDate" format="DD/MM/YYYY" type="date" placeholder :required="true"
                    v-model="obj.dateTo" />
            </b-col>
        </b-row>
        <div class="text-center">
            <Button _key="btnExecuteImport" :disabled="disabled" title="Importar" backGroundColor="#0A36DB"
                color="white" size="small" :clicked="executeImport" />
        </div>
    </div>
</template>
<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "ImportPluggy",
    props: ["value"],
    components: {
        Button,
        DateTime,
        Select
    },
    data() {
        return {
            urlGetTransactions: "/api/v1/finance/bank-reconciliation/get-all-transactions",
            urlGetTransactionsToday: "/api/v1/finance/bank-reconciliation/get-all-transactions-today",
            obj: {
                bankAccount: {},
                dateFrom: "",
                dateTo: "",
            },
            loading: true,
        }
    },
    mounted() {
        // this.$emit("input", this.loading);
        //  this.getTransactionsToday();
    },
    computed: {
        disabled() {
            if (this.obj.bankAccount == {} || this.obj.dateFrom == "" || this.obj.dateTo == "") return true;
            return false;
        }
    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        ...mapMutations("generic", ["addEvent", "hideModal", "removeLoading"]),
        getTransactionsToday() {
            this.loading = true;
            let params = {
                url: this.urlGetTransactionsToday,
            };
            this.getApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "importPluggy" });
                }
                this.loading = false;

                let self = this;
                setTimeout(function () {
                    self.$emit("input", this.loading);
                }, 5000);
            });

        },
        executeImport() {
            let params = {
                url: this.urlGetTransactions, obj: {
                    dateFrom: this.obj.dateFrom,
                    dateTo: this.obj.dateTo,
                    itemId: this.obj.bankAccount.any
                }
            };
            this.getApi(params).then((response) => {
                if (response.success) {
                    this.hideModal();
                    this.addEvent({ name: "importPluggy" });
                }
                this.removeLoading(["btnExecuteImport"]);
            });
        }
    }
}

</script>