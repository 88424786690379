<template>
    <div>
        <div class="div-description">
            <b-row>
                <b-col sm="10">
                    <i class="fa-solid fa-sack-dollar" :class="{ pay: item.type == 1, receive: item.type == 2 }"></i>
                    <span v-if="item.type == 1" class="pay"> Pagar </span>
                    <span class="receive" v-else> Receber </span>
                    <div class="side-by-side molded-description" v-if="item.recipientName">
                        Destinatário: {{ item.recipientName }}
                    </div>
                    <div class="side-by-side molded-description" v-if="item.description">
                        Descrição: {{ item.description }}
                    </div>
                    <div class="side-by-side molded-description">
                        Vencimento: {{ item.dueDate }}
                    </div>
                    <div class="side-by-side molded-description"
                        :class="{ pay: item.type == 1, receive: item.type == 2 }">
                        Valor: {{ item.value | currency }}
                    </div>
                </b-col>
                <b-col sm="2">
                    <div class="text-right">
                        <i class="icon-delete fa-solid fa-trash" @click="removeItem(item.id)"></i>
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-row>
            <b-col :sm="item.type == 1 ? 4 : 8" v-if="isNew">
                <Select title="Destinatário" field="recipient" :required="true" :showNewRegister="false"
                    url="api/v1/finance/payable-receivable/select-all-recipient" v-model="item.recipient">
                </Select>
            </b-col>
            <b-col sm="4" v-if="isNew">
                <InputText title="Descrição" field="description" :required="true" :maxLength="200"
                    v-model="item.description" />
            </b-col>
            <b-col sm="4" v-if="item.type == 1 && isNew">
                <Select title="Locação Vinculada" field="type" :markFormDirty="false"
                    url="api/v1/rental/rent/select-all" v-model="item.rent" :showNewRegister="false">
                </Select>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="4">
                <Select title="Categoria" url="api/v1/finance/chart-of-accounts/select-all"
                    :propsParams="{ any: typeCostCenter }" :showNewRegister="false" :required="true"
                    v-model="item.chartOfAccounts">
                </Select>
            </b-col>
            <b-col sm="4">
                <Select title="Tipo de Pagamento" url="/api/v1/finance/type-payment/select-all" :showNewRegister="false"
                    :required="true" v-model="item.typePayment">
                </Select>
            </b-col>
            <b-col sm="4">
                <Select title="Conta Bancária" url="api/v1/finance/bank-account/select-all" :showNewRegister="false"
                    :required="true" v-model="item.bankAccount">
                </Select>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";


export default {
    name: "ItemReconciliation",
    props: {
        item: Object,
        isNew: Boolean,
        removed: Function,
    },
    components: {
        Select, InputText, InputDecimal, Alert
    },
    computed: {
        typeCostCenter() {
            return this.item.type == 1 ? 2 : 1;
        }
    },
    methods: {
        removeItem(id) {
            if (this.removed) this.removed(id);
        },
    }
}

</script>
<style scoped>
.molded-description {
    background-color: #f0f0f0;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 50px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.pay {
    color: red;
}

.receive {
    color: darkblue;
}

.icon-delete {
    font-size: 16px;
    color: red;
    cursor: pointer;
}

.div-description {
    margin-bottom: 15px;
}
</style>